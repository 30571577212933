
import { Vue, Component } from "vue-property-decorator";
import dayjs from "dayjs";
import { searchError, ErrorInfo } from "@/api/modules/getmachine";
@Component({
  name: "ErrorEq",
})
export default class ErrorEq extends Vue {
  private tableData: Array<any> = [];
  private pageNo = 1;
  private pageSize = 10;
  private form = { search: "" };
  private queryparam = "";
  private dialogFormVisible = false;
  private form2 = { status_desc: "" };
  private id = "";

  private async mounted() {
    //let res =await getMachineNum(1)
    let res = await searchError(1)
    this.tableData = res.list;
    this.pageSize = res.total
    //this.pageNo = res.page_count
    console.log("默认第一页", res)
  }
  private filterTag = (value: string, row: any) => {
    console.log(row, value)
    return row.status_desc === value
  }
  private handleSizeChange(val: number) {
    console.log(`每页 ${val} 条`);
    this.pageSize = val;
    this.pageNo = 1;
  }

  private handleCurrentChange(val: number) {
    this.pageNo = val;
    console.log(`当前页: ${val}`);
    let str = this.queryparam ? "&search=" + this.queryparam : ""
    let param = val + str
    console.log(`当前字符串: ${param}`);
    searchError((param)).then((res) => {
      this.tableData = res.list;
      this.pageSize = res.total
    })

  }

  private open(id: string) {
    this.id = id
    this.dialogFormVisible = true
  }


  private async onSubmit() {

    let param = {
      id: this.id,
      status_desc: this.form2.status_desc
    }

    await ErrorInfo(param)
    this.dialogFormVisible = false
    this.id = ""
    let res = await searchError(1)
    this.tableData = res.list;
    this.pageSize = res.total
  }
}
